import React, { FC, useEffect, useState } from 'react';
import qs from 'query-string';

import {
  getGlossaryTerms,
  GlossaryTerm,
  getPreviewArticle,
  Article
} from 'superclient';

import ArticleDetail from '../templates/template-article';
import { Superclient } from '../clients/superclient';

const PreviewPage: FC = () => {
  const [article, setArticle] = useState<Article>();
  const [glossaryTerms, setGlossaryTerms] = useState<GlossaryTerm[]>();

  useEffect(() => {
    const params = qs.parse(window.location.search);
    const { id } = params;

    if (id) {
      Superclient.getPreviewArticle(id as string)
        .then(setArticle)
        .catch((error) => {
          console.log('Error fetching article preview', error);
        });
    }
    Superclient.getGlossaryTerms().then((terms) => setGlossaryTerms(terms));
  }, []);

  if (typeof window === 'undefined' || !glossaryTerms) {
    return <div>Loading...</div>;
  }

  return <ArticleDetail pageContext={{ article, glossaryTerms }} />;
};

export default PreviewPage;
